import '../scss/main.scss';

import { Fancybox } from '@fancyapps/ui';
import Flickity from 'flickity';
import LazyLoad from 'vanilla-lazyload';

import { checkMobileDevice } from './Functions';

import './PhoneMask';

let googleMap;
let marker;

function setMapPosition(lat, lng, zoom = 12) {
  lat = Number(lat);
  lng = Number(lng);
  let newPosition = { lat: lat, lng: lng }; // New coordinates

  if (googleMap) {
    googleMap.setCenter(newPosition); // Use setCenter instead of googleMap.center
    googleMap.setZoom(zoom); // Use setZoom instead of googleMap.zoom
    if (marker) {
      marker.position = newPosition;
    }
  } else {
    console.error('Google Map is not initialized');
  }
}

async function initMap(lat, lng) {
  const { Map } = await google.maps.importLibrary('maps');
  const { AdvancedMarkerElement } = await google.maps.importLibrary('marker');

  if (googleMap != null) {
    setMapPosition(lat, lng);
  } else {
    const position = { lat: lat, lng: lng };
    googleMap = new Map(document.getElementById('map'), {
      mapId: 'd4aac1ef02aed24c',
      zoom: 12,
      center: position,
      disableDefaultUI: true,
      gestureHandling: false,
      keyboardShortcuts: false,
    });

    marker = new AdvancedMarkerElement({
      map: googleMap,
      position: position,
    });
  }
}

const general = {
  init: function () {
    document.addEventListener('DOMContentLoaded', () => {
      this.mainMenuShow();
      this.productImages();
      this.businessImages();
      this.bestProductsCarousel();
      this.partnersSlider();
      this.phoneMask();
      this.showModal();
      this.googleMap();
      this.getCatalog();
      this.pagination();
      this.notFoundFormSend();
      this.cookieBlock();
      this.fixHeightMobile();
      this.contactsPopup();
      this.layoutCarousel();

      $('[href="#"]').on('click', function (event) {
        event.preventDefault();
      });

      general.removeEmptyParams();

      window.initMap = initMap;

      const lazyLoadInstance = new LazyLoad({});
    });
  },

  mainMenuShow: function () {
    $('[data-open-menu]').on('click', function () {
      $('.hamburger').toggleClass('active');
      $('.header__nav').toggleClass('active');
    });
  },

  contactsPopup: function () {
    const toggleButton = document.querySelector('#popup-contacts-button');
    const contacts = document.querySelector('#popup-contacts-info');
    const toggleButtonMessageIcon = document.querySelector(
      '.popup-contacts__button__icon-open',
    );
    const toggleButtonCloseIcon = document.querySelector(
      '.popup-contacts__button__icon-close',
    );

    toggleButton.addEventListener('click', toggleWidget);
    contacts.addEventListener('click', (e) => e.stopPropagation());

    document.addEventListener('click', (e) => {
      if (
        e.target !== contacts &&
        contacts.classList.contains('show') === true
      ) {
        closeWidget();
      }
    });

    function toggleWidget(e) {
      e.stopPropagation();
      contacts.classList.toggle('show');
      toggleButtonMessageIcon.classList.toggle('show');
      toggleButtonCloseIcon.classList.toggle('show');
    }

    function closeWidget() {
      contacts.classList.remove('show');
      toggleButtonMessageIcon.classList.toggle('show');
      toggleButtonCloseIcon.classList.toggle('show');
    }
  },

  productImages: function () {
    const products = document.querySelector('.product_carousel') || [];
    if (products.length === 0) return;

    const productCarousel = new Flickity(products, {
      cellAlign: 'left',
      friction: 0.3,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
    });

    Fancybox.bind('[data-fancybox="gallery"]', {});
  },

  bestProductsCarousel: function () {
    const bestProposals = document.querySelector('.best-proposals__list') || [];
    if (bestProposals.length === 0) return;

    const bestProposalsCarousel = new Flickity(bestProposals, {
      cellAlign: 'left',
      friction: 0.3,
      prevNextButtons: false,
      pageDots: false,
      autoPlay: 4000,
    });
  },

  businessImages: function () {
    const business = document.querySelector('.business_carousel') || [];
    if (business.length === 0) return;

    const businessCarousel = new Flickity(business, {
      cellAlign: 'center',
      friction: 0.3,
      prevNextButtons: false,
      pageDots: false,
      imagesLoaded: true,
    });

    Fancybox.bind('[data-fancybox="gallery"]', {});
  },

  layoutCarousel: function () {
    const layouts = document.querySelectorAll('.layout_carousel');
    // if (layouts.length === 0) return;

    layouts.forEach((layout) => {
      const layoutCarousel = new Flickity(layout, {
        cellAlign: 'left',
        friction: 0.3,
        prevNextButtons: false,
        pageDots: false,
        imagesLoaded: true,
      });
    });

    Fancybox.bind('[data-fancybox="gallery"]', {});
  },

  partnersSlider: function () {
    const partners = document.querySelector('.partners__carousel') || [];
    if (partners.length === 0) return;

    const partnersCarousel = new Flickity(partners, {
      cellAlign: 'center',
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      freeScroll: true,
    });

    partnersCarousel.x = 0;

    function autoPlay() {
      partnersCarousel.x = partnersCarousel.x - 0.25;
      partnersCarousel.settle(partnersCarousel.x);
      let requestId = window.requestAnimationFrame(autoPlay);
    }

    autoPlay();
  },

  showModal: function () {
    $('[data-modal]').each(function () {
      $(this).on('click', function () {
        let dataModal = $(this).data('modal');
        modalOpen($(`#${dataModal}`));

        if (dataModal === 'make-order') {
          let productId = $(this).data('item-id');
          console.log(productId);
          $(`#${dataModal} input[name="product_id"]`).val(productId);
        } else if (dataModal === 'map-modal') {
          const lat = $(this).data('lat')
            ? Number($(this).data('lat').toString().replace(',', '.'))
            : 50.4265807;
          const lng = $(this).data('lng')
            ? Number($(this).data('lng').toString().replace(',', '.'))
            : 30.5358109;

          setMapPosition(lat, lng, 15);
        }
      });
    });
  },

  phoneMask: function () {
    $('.form_tel').mask('+38 (099) 999-99-99');

    $.fn.setCursorPosition = function (pos) {
      if ($(this).get(0).setSelectionRange) {
        $(this).get(0).setSelectionRange(pos, pos);
      } else if ($(this).get(0).createTextRange) {
        let range = $(this).get(0).createTextRange();
        range.collapse(true);
        range.moveEnd('character', pos);
        range.moveStart('character', pos);
        range.select();
      }
    };

    $('input[type="tel"]').click(function () {
      $(this).setCursorPosition(6);
    });
  },

  getCatalog: function () {
    $('[data-catalog]').each(function () {
      $(this).on('click', function () {
        let catalogName = $(this).data('catalog');

        $('[data-catalog]').each(function () {
          $(this).removeClass('active');
          $(this).removeClass('btn_error');
        });
        $(this).addClass('active');
        $("[name='catalog_name']").val(catalogName);
      });
    });

    $('#catalog_form').submit(function (e) {
      e.preventDefault();
      const catalogName = $(this).find("[name='catalog_name']").val();

      if (catalogName === '') {
        $('[data-catalog]').each(function () {
          $(this).addClass('btn_error');
        });
        return;
      }

      const catalogForm = new FormData(this);
      $.ajax({
        type: 'POST',
        url: '/catalog/get_catalog/', // Replace with the actual server script URL
        data: catalogForm,
        processData: false,
        contentType: false,
        headers: {
          'X-CSRFToken': CSRF_TOKEN,
        },
        success: function (data, status, xhr) {
          let filename = '';
          let disposition = xhr.getResponseHeader('Content-Disposition');
          if (disposition && disposition.indexOf('attachment') !== -1) {
            let filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            let matches = filenameRegex.exec(decodeURIComponent(disposition));
            if (matches != null && matches[1]) {
              filename = decodeURIComponent(matches[1].replace(/['"]/g, ''));
              filename = filename.replace('utf-8pdf_', '');
              filename = filename.replace('_', ' ');
            }
          }

          let blob = new Blob([data]);
          let link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = filename;
          link.click();

          $('#get-catalog').removeClass('active');
          $('body').removeClass('no-scroll');
        },
      });
    });
  },

  cookieBlock: function () {
    const cookieBlock = $('#cookie-block');

    if (typeof general.getCookie('agree_cookie') === 'undefined') {
      cookieBlock.addClass('show');
    }

    $('[name=cookie-agree]').on('click', function () {
      general.setCookie('agree_cookie', 'i_agree');
      cookieBlock.removeClass('show');
    });
  },

  fixHeightMobile: function () {
    const mobileHeight = $('.fix-height-mobile');
    if (checkMobileDevice() && mobileHeight.length !== 0) {
      mobileHeight.css('max-height', $(window).height());
      $('body').addClass('hidden');
    }
  },

  pagination: function () {
    $('.pagination__item').each(function (item) {
      $(this).on('click', function (e) {
        const pageNumber = $(this).data('target-page');
        general.setOrReplacePageParameter(pageNumber);
      });
    });
  },

  notFoundFormSend: function () {
    const notFoundForm = $('#not-found-form');
    if (notFoundForm.length === 0) return;

    notFoundForm.submit(function (e) {
      e.preventDefault();

      $.ajax({
        type: 'POST',
        url: '/feedback/save_not_found_feedback/',
        data: new FormData(e.target),
        dataType: 'json',
        contentType: false,
        cache: false,
        processData: false,
        success: function (response) {
          const currentURL = window.location.href;

          const parts = currentURL.split('?');
          window.location.href = parts[0];
        },
      });
    });
  },

  setOrReplacePageParameter: function (newPageNumber) {
    let currentUrl = window.location.href;

    let urlParts = currentUrl.split('?');
    let baseUrl = urlParts[0];
    let queryString = urlParts[1] || '';

    let queryParams = queryString.split('&');

    let params = {};

    for (let i = 0; i < queryParams.length; i++) {
      let param = queryParams[i].split('=');
      params[param[0]] = param[1];
    }

    params['page'] = newPageNumber;

    let newQueryString = $.param(params);

    window.location.href = baseUrl + '?' + newQueryString;
  },

  getCookie: function (name) {
    let value = `; ${document.cookie}`;
    let parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
  },

  setCookie: function (name, cookie) {
    document.cookie = `${name}=${cookie}`;
  },

  removeEmptyParams: function () {
    let url = window.location.href;
    let urlParts = url.split('?');

    if (urlParts.length >= 2) {
      let params = urlParts[1].split('&');

      params = $.grep(params, function (param) {
        return param.split('=')[1] !== '';
      });

      let cleanUrl =
        urlParts[0] + (params.length > 0 ? '?' + params.join('&') : '');
      window.history.replaceState({}, document.title, cleanUrl);
    }
  },

  googleMap: function () {
    const $mapElement = $('#map');

    if ($mapElement.length === 0) return;

    const lat = $mapElement.data('lat')
      ? Number($mapElement.data('lat').toString().replace(',', '.'))
      : 50.4265807;
    const lng = $mapElement.data('lng')
      ? Number($mapElement.data('lng').toString().replace(',', '.'))
      : 30.5358109;

    initMap(lat, lng);
  },
};

general.init();

$(document).ready(() => {
  $('.popup__close').each(function (index) {
    $(this).on('click', function () {
      let dataModal = $(this).data('modal-close');
      modalClose($(`#${dataModal}`));
    });
  });

  $('.popup').each(function () {
    $(this).on('click', function (event) {
      if (
        !event.target.closest('.popup__body') ||
        event.target.closest('.popup__close')
      ) {
        $(this).removeClass('active');
        $('body').removeClass('no-scroll');
      }
    });
  });

  $('.dropdown-inner').each(function (index) {
    $(this).on('click', function (e) {
      $(this).parent().parent().toggleClass('active');
    });
  });

  $('.products__favourite').each(function (index) {
    $(this).on('click', function () {
      let productId = $(this).data('item-id');
      $(this).addClass('active');

      $.ajax({
        url: '/favourite/addProduct/',
        type: 'POST',
        data: {
          productId: productId,
        },
        headers: {
          'X-CSRFToken': CSRF_TOKEN,
        },
      });
    });
  });

  $('.delete-favourite').each(function (e) {
    $(this).on('click', function () {
      let productId = $(this).data('item-id');

      $.ajax({
        url: '/favourite/deleteProduct/',
        type: 'POST',
        data: {
          productId: productId,
        },
        headers: {
          'X-CSRFToken': CSRF_TOKEN,
        },
      }).done(function () {
        location.reload();
      });
    });
  });

  $('#make-order').submit(function (e) {
    e.preventDefault();
    let modal = $(this);

    $.ajax({
      type: 'POST',
      url: '/order/makeOrder/',
      data: new FormData(e.target),
      dataType: 'json',
      contentType: false,
      cache: false,
      processData: false,
      success: function (response) {
        modalClose(modal);
        modal.find('input[name="phone_number"]').val('');
        modal.find('input[name="full_name"]').val('');
        modal.find('input[name="product_id"]').val('');
      },
    });
  });

  $('#contact-us-form').on('submit', function (e) {
    e.preventDefault();
    let modal = $(this);

    $.ajax({
      type: 'POST',
      url: '/feedback/save_feedback/',
      data: new FormData(e.target),
      dataType: 'json',
      contentType: false,
      cache: false,
      processData: false,
      success: function (response) {
        $('#contact-us').removeClass('active');
        $('body').removeClass('no-scroll');
      },
    });
  });

  $('.menu_close').on('click', function (event) {
    $('.filters').toggleClass('active');
  });

  $('.filters').on('submit', function () {
    general.removeEmptyParams();
  });

  $('.filters__title.list').on('click', function (event) {
    $(this)
      .toggleClass('active')
      .next('.filters-list__inputs')
      .toggleClass('active');
  });
});

function modalOpen(modal) {
  modal.addClass('active');
  $('body').addClass('no-scroll');
}

function modalClose(modal) {
  modal.removeClass('active');
  $('body').removeClass('no-scroll');
}
